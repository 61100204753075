import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col, useScreenClass, Hidden } from "react-grid-system"
import styled from "styled-components"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Main from "../components/main"
import DownloadForm from "../components/forms/downloadsForm"
import CtaBanner from "../components/ctaBanner"

const StyledMain = styled(Main)`
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.tablet}) {
    padding-bottom: 0;
  }
`

const InnerCol = styled(Col)`
  max-width: 596px !important;
  ul {
    padding-left: 1em;
  }
  @media only screen and (max-width: 1366px) {
    max-width: 100% !important;
  }
  @media only screen and (max-width: 1028px) {
  }
  @media only screen and (max-width: 768px) {
  }
`

const ContactWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.light};
  overflow: hidden;
  position: relative;
  padding-top: 3.4rem;
`

const DownloadPage = (props) => {
  const {
    cta,
    image,
    name,
    overview,
    seoTitle,
    seoDescription,
    seoHelmet,
    slug,
  } = props.data.contentfulDownloads
  const screenClass = useScreenClass()

  return <>
    <SEO
      title={seoTitle ? seoTitle : ``}
      description={
        seoDescription && seoDescription.seoDescription
          ? seoDescription.seoDescription
          : ``
      }
      pageUrl={props?.location?.href}
      image={image?.file?.url}
    >
      {seoHelmet?.seoHelmet || null}
    </SEO>
    <Layout>
      <StyledMain>
        <Container
          style={{
            maxWidth: "100%",
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <Row>
            <Col lg={6}>
              <Row justify="end">
                <InnerCol
                  style={{
                    paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                      ? "3.4rem"
                      : "33px",
                    paddingRight: ["md", "lg", "xl"].includes(screenClass)
                      ? ".4rem"
                      : "33px",
                  }}
                >
                  <h1 className={`headline`}>{name}</h1>
                  {documentToReactComponents(JSON.parse(overview.raw))}
                </InnerCol>
              </Row>
            </Col>
            <Col>
              <DownloadForm />
            </Col>
          </Row>
        </Container>
      </StyledMain>
      <GatsbyImage
        image={image.localFile && image.localFile.childImageSharp
          ? image.localFile.childImageSharp.gatsbyImageData
          : image.fluid} />
    </Layout>
  </>;
}

export const query = graphql`
  query AssetsQuery($slug: String!) {
    contentfulDownloads(slug: { eq: $slug }) {
      id
      slug
      name
      overview {
        raw
      }
      image {
        localFile {
          ...workPreview
        }
        fluid(maxWidth: 960, quality: 95) {
          ...GatsbyContentfulFluid_withWebp
        }
        file {
          url
        }
      }
      cta {
        id
        name
        ctaTitle
        ctaBody
        ctaButton
      }
      seoTitle
      seoDescription {
        seoDescription
      }
      seoHelmet {
        seoHelmet
      }
    }
  }
`

export default DownloadPage
