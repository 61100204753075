import React, { useState } from 'react'
import { graphql, useStaticQuery, Link, navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import styled from 'styled-components'
import { Container, Row, Col, useScreenClass } from 'react-grid-system'
import { useForm } from 'react-hook-form';
import {
  Paper,
  Grid,
  TextField,
  Button
} from '@material-ui/core';

const Wrapper = styled.div`
  min-width: 100%;
  padding-bottom: 68px;
  width: 100%;
  @media only screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding-bottom: 20px;
    padding-top: 20px;
  }
`

const CtaButton = styled(Button) `
    background: ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.fonts.sansSerif};
`

const AWS_GATEWAY_URL = process.env.GATSBY_AWS_GATEWAY_DOWNLOADS_URL;

const DownloadForm = (props) => {
  const location = useLocation()
  const screenClass = useScreenClass()
  const [submitted, setSubmitted] = useState(false);
  const {
    errors,
    formState: { isSubmitting },
    handleSubmit,
    register,
    reset,
    setError
  } = useForm();

  const onSubmit = async (data, e) => {
    data.pathName = location.pathname
    data.location = location
    try {
      await fetch(AWS_GATEWAY_URL, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      });
      setSubmitted(true);
      reset();
    } catch (error) {
      setError(
        "submit",
        "submitError",
        `Oops! There seems to be an issue! ${error.message}`
      );
    }
    navigate (
      "/thank-you-download/",
      {
        state: { data }
      }
    )
  };

  const showForm = (
    <form onSubmit={handleSubmit(onSubmit)} method="post">
      <Paper square elevation={3} style={{ padding: 50 }}>
        <Grid container alignItems="flex-start" spacing={6}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              inputProps={{ 'aria-label': 'First Name' }}
              label="First Name"
              name="firstName"
              type="text"
              error={!!errors.firstName}
              inputRef={register}
            />
            <p>{errors.firstName && "Field is required."}</p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              inputProps={{ 'aria-label': 'Last Name' }}
              label="Last Name"
              name="lastName"
              type="text"
              error={!!errors.lastName}
              inputRef={register}
            />
            <p>{errors.lastName && "Field is required."}</p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              inputProps={{ 'aria-label': 'Email' }}
              label="Email"
              name="email"
              type="email"
              error={!!errors.email}
              inputRef={register({
                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              })}
            />
            <p>{errors.email && "Field is required."}</p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Company' }}
              label="Company"
              name="company"
              type="text"
              inputRef={register}
            />
          </Grid>
          <Grid item>
            <CtaButton type="submit" size="large" variant="contained" disabled={isSubmitting} >
              Download
            </CtaButton>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );

  return (
    <Wrapper className={props.className}>
      <Container style={{
        paddingLeft: ['md', 'lg', 'xl'].includes(screenClass) ? '3rem' : '1.5625rem',
        paddingRight: ['md', 'lg', 'xl'].includes(screenClass) ? '3rem' : '1.5625rem'
      }}>
        <Row align="center" justify="center">
          <Col xl={10}>
            {errors && errors.submit}
            {showForm}
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export default DownloadForm
